export const ADD_CONDITION ='ADD_CONDITION'
export const ADD_CONDITION_SUCCESS='ADD_CONDITION_SUCCESS'
export const ADD_CONDITION_FAIL='ADD_CONDITION_FAIL'

export const UPDATE_CONDITION ='UPDATE_CONDITION'
export const UPDATE_CONDITION_SUCCESS='UPDATE_CONDITION_SUCCESS'
export const UPDATE_CONDITION_FAIL='UPDATE_CONDITION_FAIL'

export const CONDITION_LIST_REQUEST='CONDITION_LIST_REQUEST'
export const CONDITION_LIST_SUCCESS='CONDITION_LIST_SUCCESS'
export const CONDITION_LIST_FAIL='CONDITION_LIST_FAIL'

export const CONDITION_DELETE_REQUEST='CONDITION_DELETE_REQUEST'
export const CONDITION_DELETE_SUCCESS='CONDITION_DELETE_SUCCESS'
export const CONDITION_DELETE_FAIL='CONDITION_DELETE_FAIL'