// actionTypes.js
export const EXERCISE_LIST_REQUEST = "EXERCISE_LIST_REQUEST"
export const EXERCISE_LIST_SUCCESS = "EXERCISE_LIST_SUCCESS"
export const EXERCISE_LIST_FAIL = "EXERCISE_LIST_FAIL"

export const ADD_EXERCISE_REQUEST = "ADD_EXERCISE_REQUEST"
export const ADD_EXERCISE_SUCCESS = "ADD_EXERCISE_SUCCESS"
export const ADD_EXERCISE_FAILURE = "ADD_EXERCISE_FAILURE"

export const EDIT_EXERCISE_REQUEST = "EDIT_EXERCISE_REQUEST"
export const EDIT_EXERCISE_SUCCESS = "EDIT_EXERCISE_SUCCESS"
export const EDIT_EXERCISE_FAILURE = "EDIT_EXERCISE_FAILURE"

export const DELETE_EXERCISE_REQUEST = "DELETE_EXERCISE_REQUEST"
export const DELETE_EXERCISE_SUCCESS = "DELETE_EXERCISE_SUCCESS"
export const DELETE_EXERCISE_FAILURE = "DELETE_EXERCISE_FAILURE"
