
export const MODALITY_LIST_REQUEST = "MODALITY_LIST_REQUEST"
export const MODALITY_LIST_SUCCESS = "MODALITY_LIST_SUCCESS"
export const MODALITY_LIST_FAIL = "MODALITY_LIST_FAIL"

export const ADD_MODALITY_REQUEST = "ADD_MODALITY_REQUEST"
export const ADD_MODALITY_SUCCESS = "ADD_MODALITY_SUCCESS"
export const ADD_MODALITY_FAILURE = "ADD_MODALITY_FAILURE"

export const EDIT_MODALITY_REQUEST = "EDIT_MODALITY_REQUEST"
export const EDIT_MODALITY_SUCCESS = "EDIT_MODALITY_SUCCESS"
export const EDIT_MODALITY_FAILURE = "EDIT_MODALITY_FAILURE"

export const DELETE_MODALITY_REQUEST = "DELETE_MODALITY_REQUEST"
export const DELETE_MODALITY_SUCCESS = "DELETE_MODALITY_SUCCESS"
export const DELETE_MODALITY_FAILURE = "DELETE_MODALITY_FAILURE"
