// actionTypes.js
export const COMPETITION_LIST_REQUEST = "COMPETITION_LIST_REQUEST"
export const COMPETITION_LIST_SUCCESS = "COMPETITION_LIST_SUCCESS"
export const COMPETITION_LIST_FAIL = "COMPETITION_LIST_FAIL"

export const ADD_COMPETITION_REQUEST = "ADD_COMPETITION_REQUEST"
export const ADD_COMPETITION_SUCCESS = "ADD_COMPETITION_SUCCESS"
export const ADD_COMPETITION_FAILURE = "ADD_COMPETITION_FAILURE"

export const EDIT_COMPETITION_REQUEST = "EDIT_COMPETITION_REQUEST"
export const EDIT_COMPETITION_SUCCESS = "EDIT_COMPETITION_SUCCESS"
export const EDIT_COMPETITION_FAILURE = "EDIT_COMPETITION_FAILURE"

export const DELETE_COMPETITION_REQUEST = "DELETE_COMPETITION_REQUEST"
export const DELETE_COMPETITION_SUCCESS = "DELETE_COMPETITION_SUCCESS"
export const DELETE_COMPETITION_FAILURE = "DELETE_COMPETITION_FAILURE"

// New action types for additional endpoints
export const ADD_ROUND_REQUEST = "ADD_ROUND_REQUEST"
export const ADD_ROUND_SUCCESS = "ADD_ROUND_SUCCESS"
export const ADD_ROUND_FAILURE = "ADD_ROUND_FAILURE"

export const REMOVE_ROUND_ACTIVITY_REQUEST = "REMOVE_ROUND_ACTIVITY_REQUEST"
export const REMOVE_ROUND_ACTIVITY_SUCCESS = "REMOVE_ROUND_ACTIVITY_SUCCESS"
export const REMOVE_ROUND_ACTIVITY_FAILURE = "REMOVE_ROUND_ACTIVITY_FAILURE"

export const REMOVE_ROUND_REQUEST = "REMOVE_ROUND_REQUEST"
export const REMOVE_ROUND_SUCCESS = "REMOVE_ROUND_SUCCESS"
export const REMOVE_ROUND_FAILURE = "REMOVE_ROUND_FAILURE"
