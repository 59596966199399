
export const ACTIVITY_LIST_REQUEST = "ACTIVITY_LIST_REQUEST"
export const ACTIVITY_LIST_SUCCESS = "ACTIVITY_LIST_SUCCESS"
export const ACTIVITY_LIST_FAIL = "ACTIVITY_LIST_FAIL"

export const ADD_ACTIVITY_REQUEST = "ADD_ACTIVITY_REQUEST"
export const ADD_ACTIVITY_SUCCESS = "ADD_ACTIVITY_SUCCESS"
export const ADD_ACTIVITY_FAILURE = "ADD_ACTIVITY_FAILURE"

export const EDIT_ACTIVITY_REQUEST = "EDIT_ACTIVITY_REQUEST"
export const EDIT_ACTIVITY_SUCCESS = "EDIT_ACTIVITY_SUCCESS"
export const EDIT_ACTIVITY_FAILURE = "EDIT_ACTIVITY_FAILURE"

export const DELETE_ACTIVITY_REQUEST = "DELETE_ACTIVITY_REQUEST"
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS"
export const DELETE_ACTIVITY_FAILURE = "DELETE_ACTIVITY_FAILURE"

export const ADD_ACTIVITY_EXERCISE_REQUEST = "ADD_ACTIVITY_EXERCISE_REQUEST"
export const ADD_ACTIVITY_EXERCISE_SUCCESS = "ADD_ACTIVITY_EXERCISE_SUCCESS"
export const ADD_ACTIVITY_EXERCISE_FAILURE = "ADD_ACTIVITY_EXERCISE_FAILURE"

export const DELETE_ACTIVITY_EXERCISE_REQUEST = "DELETE_ACTIVITY_REQUEST"
export const DELETE_ACTIVITY_EXERCISE_SUCCESS = "DELETE_ACTIVITY_SUCCESS"
export const DELETE_ACTIVITY_EXERCISE_FAILURE = "DELETE_ACTIVITY_FAILURE"
