// actionTypes.js
export const BENEFIT_LIST_REQUEST = "BENEFIT_LIST_REQUEST"
export const BENEFIT_LIST_SUCCESS = "BENEFIT_LIST_SUCCESS"
export const BENEFIT_LIST_FAIL = "BENEFIT_LIST_FAIL"

export const ADD_BENEFIT_REQUEST = "ADD_BENEFIT_REQUEST"
export const ADD_BENEFIT_SUCCESS = "ADD_BENEFIT_SUCCESS"
export const ADD_BENEFIT_FAILURE = "ADD_BENEFIT_FAILURE"

export const EDIT_BENEFIT_REQUEST = "EDIT_BENEFIT_REQUEST"
export const EDIT_BENEFIT_SUCCESS = "EDIT_BENEFIT_SUCCESS"
export const EDIT_BENEFIT_FAILURE = "EDIT_BENEFIT_FAILURE"

export const DELETE_BENEFIT_REQUEST = "DELETE_BENEFIT_REQUEST"
export const DELETE_BENEFIT_SUCCESS = "DELETE_BENEFIT_SUCCESS"
export const DELETE_BENEFIT_FAILURE = "DELETE_BENEFIT_FAILURE"
