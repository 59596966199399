// actionTypes.js
export const EQUIPMENT_LIST_REQUEST = "EQUIPMENT_LIST_REQUEST"
export const EQUIPMENT_LIST_SUCCESS = "EQUIPMENT_LIST_SUCCESS"
export const EQUIPMENT_LIST_FAIL = "EQUIPMENT_LIST_FAIL"

export const ADD_EQUIPMENT_REQUEST = "ADD_EQUIPMENT_REQUEST"
export const ADD_EQUIPMENT_SUCCESS = "ADD_EQUIPMENT_SUCCESS"
export const ADD_EQUIPMENT_FAILURE = "ADD_EQUIPMENT_FAILURE"

export const EDIT_EQUIPMENT_REQUEST = "EDIT_EQUIPMENT_REQUEST"
export const EDIT_EQUIPMENT_SUCCESS = "EDIT_EQUIPMENT_SUCCESS"
export const EDIT_EQUIPMENT_FAILURE = "EDIT_EQUIPMENT_FAILURE"

export const DELETE_EQUIPMENT_REQUEST = "DELETE_EQUIPMENT_REQUEST"
export const DELETE_EQUIPMENT_SUCCESS = "DELETE_EQUIPMENT_SUCCESS"
export const DELETE_EQUIPMENT_FAILURE = "DELETE_EQUIPMENT_FAILURE"
