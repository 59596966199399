export const PARTICIPATOR_LIST_REQUEST = "PARTICIPATOR_LIST_REQUEST"
export const PARTICIPATOR_LIST_SUCCESS = "PARTICIPATOR_LIST_SUCCESS"
export const PARTICIPATOR_LIST_FAIL = "PARTICIPATOR_LIST_FAIL"

export const ADD_PARTICIPATOR ="ADD_PARTICIPATOR"
export const ADD_PARTICIPATOR_SUCCESS = "ADD_PARTICIPATOR_SUCCESS"
export const ADD_PARTICIPATOR_FAIL="ADD_PARTICIPATOR_FAIL"

export const REMOVE_PARTICIPATOR_REQUEST_LIST ="REMOVE_PARTICIPATOR_REQUEST_LIST"
export const REMOVE_PARTICIPATOR_REQUEST_LIST_SUCCESS = "REMOVE_PARTICIPATOR_REQUEST_LIST_SUCCESS"
export const REMOVE_PARTICIPATOR_REQUEST_LIST_FAIL="REMOVE_PARTICIPATOR_REQUEST_LIST_FAIL"

export const FETCH_PARTICIPATOR_cOMMUNITIES ="FETCH_PARTICIPATOR_cOMMUNITIES"
export const FETCH_PARTICIPATOR_cOMMUNITIES_SUCCESS = "FETCH_PARTICIPATOR_cOMMUNITIES_SUCCESS"
export const FETCH_PARTICIPATOR_cOMMUNITIES__FAIL="ADD_PARTICIPATOR_FAIL"

export const ADD_PARTICIPATOR_REQUEST = "ADD_PARTICIPATOR_REQUEST"
export const ADD_PARTICIPATOR_FAILURE = "ADD_PARTICIPATOR_FAILURE"

export const ACCEPT_PARTICIPATOR_REQUEST = "ACCEPT_PARTICIPATOR_REQUEST"
export const ACCEPT_PARTICIPATOR_SUCCESS = "ACCEPT_PARTICIPATOR_SUCCESS"
export const ACCEPT_PARTICIPATOR_FAILURE = "ACCEPT_PARTICIPATOR_FAILURE"

export const DELETE_PARTICIPATOR_REQUEST = "DELETE_PARTICIPATOR_REQUEST"
export const DELETE_PARTICIPATOR_SUCCESS = "DELETE_PARTICIPATOR_SUCCESS"
export const DELETE_PARTICIPATOR_FAILURE = "DELETE_PARTICIPATOR_FAILURE"

export const PARTICIPATOR_LIST_MAIN_REQUEST = "PARTICIPATOR_LIST_MAIN_REQUEST"
export const PARTICIPATOR_LIST_MAIN_SUCCESS = "PARTICIPATOR_LIST_MAIN_SUCCESS"
export const PARTICIPATOR_LIST_MAIN_FAIL = "PARTICIPATOR_LIST_MAIN_FAIL"


export const ALL_COMPETITION_LIST ="ALL_COMPETITION_LIST"
export const ALL_COMPETITION_LIST_SUCCESS = "ALL_COMPETITION_LIST_SUCCESS"
export const ALL_COMPETITION_LIST__FAIL="ALL_COMPETITION_LIST_FAIL"

export const JOIN_PARTICIPATE ="JOIN_PARTICIPATE"
export const JOIN_PARTICIPATE_SUCCESS = "JOIN_PARTICIPATE"
export const JOIN_PARTICIPATE_FAIL="JOIN_PARTICIPATE"

export const ALL_PARTICIPATED_LIST ="ALL_PARTICIPATED_LIST"
export const ALL_PARTICIPATED_LIST_SUCCESS = "ALL_PARTICIPATED_LIST_SUCCESS"
export const ALL_PARTICIPATED_LIST_FAIL="ALL_PARTICIPATED_LIST_FAIL"