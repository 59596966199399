
export const MUSCLE_LIST_REQUEST = "MUSCLE_LIST_REQUEST"
export const MUSCLE_LIST_SUCCESS = "MUSCLE_LIST_SUCCESS"
export const MUSCLE_LIST_FAIL = "MUSCLE_LIST_FAIL"

export const ADD_MUSCLE_REQUEST = "ADD_MUSCLE_REQUEST"
export const ADD_MUSCLE_SUCCESS = "ADD_MUSCLE_SUCCESS"
export const ADD_MUSCLE_FAILURE = "ADD_MUSCLE_FAILURE"

export const EDIT_MUSCLE_REQUEST = "EDIT_MUSCLE_REQUEST"
export const EDIT_MUSCLE_SUCCESS = "EDIT_MUSCLE_SUCCESS"
export const EDIT_MUSCLE_FAILURE = "EDIT_MUSCLE_FAILURE"

export const DELETE_MUSCLE_REQUEST = "DELETE_MUSCLE_REQUEST"
export const DELETE_MUSCLE_SUCCESS = "DELETE_MUSCLE_SUCCESS"
export const DELETE_MUSCLE_FAILURE = "DELETE_MUSCLE_FAILURE"
